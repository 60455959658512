import styled, { css } from 'styled-components'

/* style */
const HeadStyles = css`
  margin: 15px 0 10px;
  font-size: 20px;
  font-weight: 500;
  @media (min-width: 768px) {
    margin: 30px 0 15px;
    font-size: 30px;
  }
`
/* style */

/* function */
const getHeadingModifiedStyled = ({mode}) => {
  if(mode === 'title') return HeadStyles
}
/* function */

/* component */
export const HeadingFirstComponent = styled.h1`
  ${getHeadingModifiedStyled}
`
export const HeadingSecondComponent = styled.h2`
  ${getHeadingModifiedStyled}
`
export const HeadingThirdComponent = styled.h3`
  ${getHeadingModifiedStyled}

`
export const HeadingFourthComponent = styled.h4`
  ${getHeadingModifiedStyled}

`
export const HeadingFifthComponent = styled.h5`
  ${getHeadingModifiedStyled}

`
export const HeadingSixthComponent = styled.h6`
  ${getHeadingModifiedStyled}

`
/* component */