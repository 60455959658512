import styled, { css } from 'styled-components'

/* style */
const GlobalInputStyles = css`
  position: relative;
  padding: 9px 16px;
  border: 1px solid hsl(0,0%,80%);  
  border-radius: 4px;
  background-color: #fff;
  font-size: 13px;
  height: auto;
  max-height: 36px;
  width: 100%;
  &:focus {
    // border-color: #fc5c64;
    outline: none;
  }
  &::placeholder {
    font-style: italic;
  }
`
const SmallStyles = css`
  padding: 10px 14px;
`
const SubmitStyles = css`
  color: #fff;
  background-color: rgb(252, 92, 100);
  border: 0;
  cursor: pointer;
  font-weight: 600;
`
const DisabledStyles = css`
  &:disabled {
    background-color: #ededed;
  }
`

/* style */

/* function */
const getInputModifiedStyled = ({mode, ...props}) => {
  let modifiedStyled = ""
  if(mode === 'small') modifiedStyled += SmallStyles

  if(props.iconLeft){
    modifiedStyled += ` padding-left: 35px; `
  }
  if(props.iconRight){
    modifiedStyled += ` padding-right: 35px; `
  }

  if(props.isError) {
    modifiedStyled += ` border:1px solid #fc5c64; `
  }

  return modifiedStyled
}
const getInputSubmitStyled = ({type}) => {
  if(type === 'submit') return SubmitStyles
}
/* function */

/* component */
export const InputContainer = styled.input.attrs(props => ({
  type: (props.type) ? props.type : 'text',
  autoComplete: (props.autocomplete) ? props.autocomplete : 'off' 
}))`
  ${GlobalInputStyles}
  ${getInputModifiedStyled}
  ${getInputSubmitStyled}
  ${DisabledStyles}
`
/* component */

