import styled, { css } from 'styled-components'

/* style */
const tagredMobileOnlyStyles = css` 
  display: none;
  @media (min-width: 320px) and (max-width: 480px) {
    background-color: #429ddf;
    color: #fff;
    font-size: 10px;
    align-items: center;
    height: 100%;
    display: grid;
    padding: 0 5px;
    position: relative;
    margin-right: 8px;
    width: 90px;
    border-radius: 6px;
    min-height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    &:after {
      content: "";
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #429ddf;
      position: absolute;
      right: -6px;
      top: 50%;
      transform: translate(0, -55%);
    }
  }
`
const blockStyles = css`
  display: block;
  margin: 8px 0;
`
const EmptyStyles = css`
  display: grid;
  text-align:center;
  align-items: center;
  margin: 15px auto;
  color: #a9a9a9;
`
/* style */

/* function */
const getLabelModifiedStyled = ({mode, ...props}) => {
  let modifiedStyled = ""
  if(mode === 'tagredmobile') modifiedStyled += tagredMobileOnlyStyles
  else if(mode === 'block') modifiedStyled += blockStyles
  else if(mode === 'empty') modifiedStyled += EmptyStyles

  if(props['font-size']) modifiedStyled += `
    font-size: ${(parseInt(props['font-size']) - 2)}px;
    @media (min-width: 768px) {
      font-size: ${props['font-size']};
    }
  `
  return modifiedStyled

}
/* function */

/* component */
export const LabelContainer = styled.label`
  ${getLabelModifiedStyled}
`
/* component */