import React from 'react'

import { InputContainer } from '../input/input.styles'

const InputComponent = ({ type, autocomplete, innerRef, ...props }) => {
  return (
    <InputContainer type={type} autocomplete={autocomplete} ref={innerRef} {...props}/>
  )
}

export default InputComponent