import React from 'react'

import { LabelContainer } from '../label/label.styles'

const LabelComponent = ({ children, mode, ...props }) => {
  return (
    <LabelContainer mode={mode} {...props}>{children}</LabelContainer>
  )
}

export default LabelComponent