import React from 'react'
import PropTypes from 'prop-types'

import { TableContainer, TableHeadRow, TableHeadColumn, TableBodyContainer, TableBodyRow, TableBodyColumn, TableFootContainer, TableFootRow, TableFootColumn, TableFinalContainer, TableFinalRow, TableFinalColumn } from '../table/table.styles'

const TableComponent = ({ children, mode, name, col, colsize, firstwidth, secondwidth, lastwidth, align, template, template1024, background }) => {
  const HeadChildrenFilter = children.filter(child =>  (child !== null && child !== '') && child.props['aria-label'] === 'head'),
    BodyChildrenFilter = children.filter(child =>  (child !== null && child !== '') && child.props['aria-label'] === 'body'),
    FootChildrenFilter = children.filter(child =>  (child !== null && child !== '') && child.props['aria-label'] === 'foot'),
    FinalChildrenFilter = children.filter(child =>  (child !== null && child !== '') && child.props['aria-label'] === 'final')
  let BodyChildrenArray = [],
    FootChildrenArray = [],
    FinalChildrenArray = []
  if(BodyChildrenFilter.length) {
    if(BodyChildrenFilter[0].props.children.length > 1) {
      let newArrayBodyChildren = []
      BodyChildrenFilter[0].props.children.forEach(body => {
        let _bodyArray = Array.isArray(body) ? body : [].concat(body)
        _bodyArray.forEach(_body => {
          newArrayBodyChildren.push(_body)
        })
      })
      BodyChildrenArray = newArrayBodyChildren
    }else {
      BodyChildrenArray = Array.isArray(BodyChildrenFilter[0].props.children) ? BodyChildrenFilter[0].props.children : [].concat(BodyChildrenFilter[0].props.children)
    }
  }
  if(FootChildrenFilter.length) {
    if(FootChildrenFilter[0].props.children.length > 1) {
      let newArrayFootChildren = []
      FootChildrenFilter[0].props.children.forEach(foot => {
        if(foot !== null) {
          let _footArray = Array.isArray(foot) ? foot : [].concat(foot)
          _footArray.forEach(_foot => {
            newArrayFootChildren.push(_foot)
          })
        }
      })
      FootChildrenArray = newArrayFootChildren
    }else {
      FootChildrenArray = Array.isArray(FootChildrenFilter[0].props.children) ? FootChildrenFilter[0].props.children : [].concat(FootChildrenFilter[0].props.children)
    }
  }
  if(FinalChildrenFilter.length) {
    if(FinalChildrenFilter[0].props.children.length > 1) {
      let newArrayFinalChildren = []
      FinalChildrenFilter[0].props.children.forEach(final => {
        let _finalArray = Array.isArray(final) ? final : [].concat(final)
        _finalArray.forEach(_final => {
          newArrayFinalChildren.push(_final)
        })
      })
      FinalChildrenArray = newArrayFinalChildren
    }else {
      FinalChildrenArray = Array.isArray(FinalChildrenFilter[0].props.children) ? FinalChildrenFilter[0].props.children : [].concat(FinalChildrenFilter[0].props.children)
    }
  }

  const TableHeadEl = (HeadChildrenFilter.length) ? HeadChildrenFilter[0].props.children.filter(head => head !== null).map((head, index) => <TableHeadColumn key={index} {...(head.props.style && { style: head.props.style })}>{head.props.children}</TableHeadColumn>) : null,
    TableBodyEl = (BodyChildrenArray.length) ? BodyChildrenArray.map((body, index) =>  <TableBodyRow key={index}> { body.props.children.filter(child => child !== null).map((child, indexChild) => <TableBodyColumn key={indexChild} {...child.props}>{child.props.children}</TableBodyColumn>) } </TableBodyRow>) : null,
    TableFootEl =  (FootChildrenArray.length) ? FootChildrenArray.map((foot, index) =>  <TableFootRow key={index}> { foot.props.children.filter(child => child !== null).map((child, indexChild) => <TableFootColumn key={indexChild} {...child.props}>{child.props.children}</TableFootColumn>) } </TableFootRow>) : null,
    TableFinalEl =  (FinalChildrenArray.length) ? FinalChildrenArray.map((foot, index) =>  <TableFinalRow key={index}> { foot.props.children.filter(child => child !== null).map((child, indexChild) => <TableFinalColumn key={indexChild} {...child.props}>{child.props.children}</TableFinalColumn>) } </TableFinalRow>) : null
    
  return (
    <TableContainer mode={mode} name={name} col={col} colsize={colsize} firstwidth={firstwidth} secondwidth={secondwidth} lastwidth={lastwidth} template={template} template1024={template1024}>
      <TableHeadRow>
        {TableHeadEl}
      </TableHeadRow>
      <TableBodyContainer align={align} background={background}>
        {TableBodyEl}
      </TableBodyContainer>
      {
        TableFootEl &&
          <TableFootContainer align={align} background={background}>
            {TableFootEl}
          </TableFootContainer>
      }
      {
        TableFinalEl &&
        <TableFinalContainer align={align} background={background}>
          {TableFinalEl}
        </TableFinalContainer>
      }
    </TableContainer>
  )
}

TableComponent.defaultProps = {
  name: `table_${new Date().getTime()}`,
  colsize: '1fr'
}

TableComponent.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string, 
  mode: PropTypes.string, 
  col: PropTypes.string,
  colsize: PropTypes.string,
  firstwidth: PropTypes.string,
  secondwidth: PropTypes.string,
  Lastwidth: PropTypes.string,
  align: PropTypes.string,
  template: PropTypes.string,
  background: PropTypes.string,
}

export default TableComponent