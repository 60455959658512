import React from 'react'

import { HeadingFirstComponent, HeadingSecondComponent, HeadingThirdComponent, HeadingFourthComponent, HeadingFifthComponent, HeadingSixthComponent } from '../heading/heading.styles'

const HeadingComponent = ({ children, type, mode }) => {
  let HeadingComponentEl
  switch (type) {
    case 'h1':
      HeadingComponentEl = <HeadingFirstComponent mode={mode}>{children}</HeadingFirstComponent>
      break
    case 'h2':
      HeadingComponentEl = <HeadingSecondComponent mode={mode}>{children}</HeadingSecondComponent>
      break
    case 'h3':
      HeadingComponentEl = <HeadingThirdComponent mode={mode}>{children}</HeadingThirdComponent>
      break
    case 'h4':
      HeadingComponentEl = <HeadingFourthComponent mode={mode}>{children}</HeadingFourthComponent>
      break
    case 'h5':
      HeadingComponentEl = <HeadingFifthComponent mode={mode}>{children}</HeadingFifthComponent>
      break
    case 'h6':
      HeadingComponentEl = <HeadingSixthComponent mode={mode}>{children}</HeadingSixthComponent>
      break
    default:
      HeadingComponentEl = <HeadingSixthComponent mode={mode}>{children}</HeadingSixthComponent>
      break  
  }
  return HeadingComponentEl
}

export default HeadingComponent