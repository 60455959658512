import styled, {css} from 'styled-components'

/* style */
const TableHeadStyles = css`
  display: none;
  @media (min-width: 768px) {
    display: grid;
    justify-items: start;
  }
`
const TableBodyStyles = css`
  display: grid;
  justify-items: start;
`
const TableColumnStyles = css`
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 6px;
  padding: 12px 15px;
  width: 100%;
  justify-content: left;
  b {
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    font-weight: 400;
  }
`
const TableSectionContainer = css`
  display: inline-grid;
  line-height: 17px;
  @media (min-width: 768px) {
    border-top: 0;
  }
`
/* style */

/* function */
const getTableModifiedStyled = ({mode, col, firstwidth, secondwidth, template, align}) => {
  let stylesTab = `
    grid-template-rows: unset;
    @media (min-width: 768px) {
      grid-template-columns: unset;
    }
  `
  if(mode === 'widersmall') return `
    ${TableHeadRow}, ${TableBodyRow}, ${TableFootRow} { 
      grid-template-rows: auto max-content;
      @media (min-width: 768px) {
        grid-template-columns: auto max-content; 
      }
    }
  `
  if(mode === 'custom') return `    
    ${TableHeadColumn} {
      padding: 10px;
    }
    ${TableBodyColumn} {
      grid-auto-flow: row;
    }
    ${TableBodyColumn}, ${TableFinalColumn} {
      padding: 12px 10px;
    }
    ${TableFootColumn} {
      padding: 12px 10px 2px;
      line-height: normal;
    }
    ${TableBodyRow}, ${TableFootRow}, ${TableFinalRow} {
      // font-size: 12px;
    }
    ${TableBodyRow} {
      border-bottom: 1px solid rgba(0,0,0, .05);
      &:last-child {
        border-bottom: 0;
      }
    }
    ${TableFootRow} {
      &:last-child {
        & ${TableFootColumn} {
          padding-bottom: 12px;
        }
      }
    }
    ${TableHeadRow}, ${TableBodyRow}, ${TableFootRow},  ${TableFinalRow} {
      grid-template-rows: unset;
      @media (min-width: 768px) {
        grid-template-columns: ${template}; 
      }
    }
  `

  if(col) {
    let colModified = col-2 

    if(firstwidth && secondwidth) {
      let widthEr = (parseInt(firstwidth) - 100) >= 100  ? (parseInt(firstwidth) - 100) : parseInt(firstwidth),
         secondWidthEr = (parseInt(secondwidth) - 100) >= 100  ? (parseInt(secondwidth) - 100) : parseInt(secondwidth)
      stylesTab = `
        grid-template-rows: unset;
        @media (min-width: 768px) {
          grid-template-columns: ${widthEr}px ${secondWidthEr}px repeat(${colModified}, 1fr);
        }
        @media (min-width: 1024px) {
          grid-template-columns: ${firstwidth} ${secondwidth} repeat(${colModified}, 1fr);
        }
      `

    } else if(firstwidth) {
      colModified = col- 1
      let widthEr = (parseInt(firstwidth) - 100) >= 100  ? (parseInt(firstwidth) - 100) : parseInt(firstwidth)
      stylesTab = `
        grid-template-rows: unset;
        @media (min-width: 768px) {
          grid-template-columns: ${widthEr}px repeat(${colModified}, 1fr);
        }
        @media (min-width: 1024px) {
          grid-template-columns: ${firstwidth} repeat(${colModified}, 1fr);
        }
      `
    } else if(secondwidth) {
      let widthEr = (parseInt(secondwidth) - 100) >= 100  ? (parseInt(secondwidth) - 100) : parseInt(secondwidth)
      stylesTab = `
        grid-template-rows: unset;
        @media (min-width: 768px) {
          grid-template-columns: 1fr ${widthEr}px repeat(${colModified}, 1fr);
        }
        @media (min-width: 1024px) {
          grid-template-columns: 1fr ${firstwidth} repeat(${colModified}, 1fr);
        }
      `
    }
  }
  
  if(mode === 'normal') return `
    ${TableHeadRow}, ${TableBodyRow}, ${TableFootRow}, ${TableFinalRow} { ${stylesTab} }
  `

  if(mode === 'clear') return `
    ${TableHeadRow}, ${TableBodyRow}, ${TableFootRow}, ${TableFinalRow} { 
      ${stylesTab} 
    }
    ${TableHeadRow}, ${TableBodyContainer}, ${TableFootContainer}, ${TableFinalContainer} { {
      background-color: #fff;
      border-top: 0;
    }
  `
}

const getTableChildModifiedStyled = ({align , background, bodyheight}) => {
  let styleTabChild = ``
  if(align) {
    styleTabChild += `
      ${TableBodyColumn}, ${TableFootColumn}, ${TableFinalColumn} {
        &:not(:first-child) {
          justify-content: ${align};
        }
      }
    `
  }
  if(background) {
    styleTabChild += `
      background-color: ${background};
    `
  }
  if(bodyheight) {
    styleTabChild += `
      height: ${bodyheight};
      overflow: auto;
    `
  }
  return styleTabChild
}
/* function */

/* component */
export const TableContainer = styled.section`
  display: inline-grid;
  width: 100%;
  margin: 15px -15px;
  @media (min-width: 768px) {
    margin: 15px 0 0 0;
  }
  ${getTableModifiedStyled}
`
export const TableHeadRow = styled.section`
  ${TableHeadStyles}
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  background-color: #f7f7f7;
  border-top: 1px solid #e4e5e5;
  border-bottom: 1px solid #e4e5e5;
  align-items: center;
`
export const TableHeadColumn = styled.div`
  padding: 10px;
  @media (min-width: 1024px) {
    padding: 10px 15px;
  }
`
export const TableBodyContainer = styled.section`
  ${TableSectionContainer}  
  border-top: 1px solid #e4e5e5;
  ${getTableChildModifiedStyled}
`
export const TableBodyRow = styled.section`
  ${TableBodyStyles}
  border-bottom: 1px solid #e4e5e5;
`
export const TableBodyColumn = styled.div`
  ${TableColumnStyles}
`
export const TableFootContainer = styled.section`
  ${TableSectionContainer}  
  ${getTableChildModifiedStyled}
`
export const TableFootRow = styled.section`
  ${TableBodyStyles}
`
export const TableFootColumn = styled.div`
  ${TableColumnStyles}
`
export const TableFinalContainer = styled.section`
  ${TableSectionContainer}  
  border-top: 1px solid #e4e5e5;
  border-bottom: 1px solid #ddd;
  @media (min-width: 768px) {
    border-top: 1px solid #e4e5e5;
  }
  ${getTableChildModifiedStyled}
`
export const TableFinalRow = styled.section`
  ${TableBodyStyles}
`
export const TableFinalColumn = styled.div`
  ${TableColumnStyles}
`
/* component */
