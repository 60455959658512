import React, { Component } from 'react'
import ReactSelect from 'react-select'

import { SelectContainer } from './select.styles'
class SelectComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null,
      isActiveLabel: 'lastmonth'
    }
    this.ContainerRef = React.createRef()
    this.evToogleSelect = this.evToogleSelect.bind(this)
  }

  evToogleSelect = (e) => {
    const ContainerRef = document.getElementsByClassName('_refContainer is-shown')
    if(ContainerRef.length) {
      for(var i = 0; i < ContainerRef.length; i++){
        ContainerRef[i].classList.remove('is-shown')
      }
    }  
  }

  render() {
    const { children, plugin, type, defaultValue, options, id } = this.props
    let SelectEl = <SelectContainer>{children}</SelectContainer>
    if(plugin === 'react-select') {
      const customStyles = {
        container: (provided) => ({
          ...provided,
          display: 'inline-block',
          width: '100%',
          minHeight: '1px',
          textAlign: 'left',
          border: 'none',
          fontSize: '14px'
        }),
        control: (provided, state) => ({
          ...provided,
          borderRadius: '3px',
          minHeight: '1px',
          height: '36px',
          cursor: 'pointer',
          borderColor: this.props.isError ? '#fc5c64' : 'hsl(0,0%,80%)',
          boxShadow: 'none',
          '&:hover': {
            borderColor: state.isFocused ? '#fc5c64' : 'hsl(0,0%,80%)',
            boxShadow: 'none'
          }
        }),
        input: (provided) => ({
          ...provided,
          minHeight: '1px',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          minHeight: '1px',
          paddingTop: '0',
          paddingBottom: '0',
          color: '#757575',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          minHeight: '1px',
          height: '24px',
          display: (type=== 'tag') ? 'block' : 'none'
        }),
        clearIndicator: (provided) => ({
          ...provided,
          minHeight: '1px',
        }),
        valueContainer: (provided) => ({
          ...provided,
          minHeight: '1px',
          height: '36px',
          paddingTop: '0',
          paddingBottom: '0',
          fontWeight: '600',
        }),
        singleValue: (provided) => ({
          ...provided,
          minHeight: '1px',
          paddingBottom: '2px',
          color: 'initial'
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? '#ffffff' : '#000000',
          backgroundColor: state.isSelected || state.isFocused ? '#fc5c64' : 'transparent',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#ec5b62',
            color: '#ffffff'
          }
        }),
        menuPortal: (provided) => ({ 
          ...provided, 
          zIndex: 9999,
          font: '400 88%/1.5em Proxima Nova,sans-serif',
          letterSpacing: 'normal',
          lineHeight: 'normal',
          color: '#2c2c2c'
        }),
      }
      SelectEl = <ReactSelect 
        styles={customStyles} 
        onMenuOpen={this.evToogleSelect}
        menuPortalTarget={document.body}
        menuPosition='fixed'
        menuPlacement='auto'
        defaultValue= {defaultValue ? defaultValue : options[0] }
        {...this.props} />
    }
    return (
      <div id={id} className='_refContainer'>{SelectEl}</div>
    )
  }
}

export default SelectComponent