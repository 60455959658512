import React from 'react'

import { IconContainer } from '../icon/icon.styles'

const IconComponent = ({ mode, width, ...props }) => {
  return (
    <IconContainer mode={mode} width={width} {... props}></IconContainer>
  )
}

export default IconComponent