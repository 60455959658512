import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

/* style */
const GlobalLinkStyles = css`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    opacity: .8;
  }
`
const ClearLinkStyles = css` 
  color: #429ddf;
  text-decoration: none;
`
const ClearLinkUnderlineStyles = css`
  color: #429ddf;
  text-decoration: underline;
`
const RedStyles = css` 
  color: #ff1744;
  text-decoration: none;
`
const SmallDownloadStyles = css`
  font-size: 12px;
  color: #ff585d;
`
const ClearStyles = css`
  color: inherit;
  text-decoration: none;
`
const DisableStyles = css`
  opacity: .5;
  pointer-events: none;
`
const InitialStyles = css`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  align-items: center;
`
const ResetLinkStyles = css`
  text-decoration: none;
  cursor: default;
  &:hover { opacity: 1; }
`
const ButtonStyles = css`
  display: inline-block;
  padding: 7px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 13px;
  font-weight: 700;
  outline: none;
  height: 28px;
  position: relative;
  text-decoration: none;
`
const WhiteButtonStyles = css`
  background: transparent;
  border: 1px solid rgba(45,60,72,.12);
  color: #000;
  &:hover {
    background: #f7f7f7;
  }
`
/* style */

/* function */
const getLinkModifiedStyled = ({mode, fontSize, ...props}) => {
  let modifiedStyled = ""
  if(mode === 'clearlink') modifiedStyled += ClearLinkStyles
  if(mode === 'clearlinkunderline') modifiedStyled += ClearLinkUnderlineStyles
  else if(mode === 'redlink') modifiedStyled += RedStyles
  else if(mode === 'smalldownload') modifiedStyled += SmallDownloadStyles
  else if(mode === 'clear') modifiedStyled += ClearStyles
  else if(mode === 'disable') modifiedStyled += DisableStyles
  else if(mode === 'disableClear') modifiedStyled += DisableStyles + ClearStyles
  else if(mode === 'initial') modifiedStyled += InitialStyles + ClearStyles
  else if(mode === 'whitebutton') modifiedStyled += ButtonStyles + WhiteButtonStyles


  if(props.as === 'div') modifiedStyled += ResetLinkStyles

  if(fontSize) modifiedStyled += `
    font-size: ${fontSize};
  `

  return modifiedStyled
}
/* function */

/* component */
export const LinkContainer = styled(Link)`
  ${GlobalLinkStyles}
  ${getLinkModifiedStyled}
`
/* component */