import React from 'react'

import ContentLoader from 'react-content-loader'

/* component */
export const ContentLoaderTableOnly = props => {
  return  <ContentLoader
            height={200}
            width='100%'
            speed={1}
            backgroundColor='rgb(0,0,0)'
            foregroundColor='rgb(0,0,0)'
            backgroundOpacity={0.06}
            foregroundOpacity={0.12}
          >
            <rect x='0' y='14' rx='0' ry='0' width='100%' height='35' /> 
            <rect x='0' y='60' rx='0' ry='0' width='100%' height='17' />
            <rect x='0' y='88' rx='0' ry='0' width='100%' height='17' />
            <rect x='0' y='116' rx='0' ry='0' width='100%' height='17' />
            <rect x='0' y='144' rx='0' ry='0' width='100%' height='17' />
            <rect x='0' y='172' rx='0' ry='0' width='100%' height='17' />
          </ContentLoader>
}

export const ContentLoaderTableContentOnly = props => {
  return  <ContentLoader
            height={125}
            width='100%'
            speed={1}
            backgroundColor='rgb(0,0,0)'
            foregroundColor='rgb(0,0,0)'
            backgroundOpacity={0.06}
            foregroundOpacity={0.12}
          >
            <rect x='0' y='14' rx='0' ry='0' width='100%' height='17' />
            <rect x='0' y='42' rx='0' ry='0' width='100%' height='17' />
            <rect x='0' y='70' rx='0' ry='0' width='100%' height='17' />
            <rect x='0' y='98' rx='0' ry='0' width='100%' height='17' />
          </ContentLoader>
}