import React from 'react'

const EmptyLayout = ({ children }) => {
  return (
    <div>
      {children}
    </div>
  )
}

export default EmptyLayout