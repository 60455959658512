import React from 'react'

import { LinkContainer } from '../link/link.styles'

const LinkComponent = ({ children, to, mode, fontSize, refContainer, isDisabled, ...props }) => {
  return (
    isDisabled ? <LinkContainer as='a' to={to} href='' onClick={e => e.preventDefault()} mode={mode} fontSize={fontSize} ref={refContainer} {...props}>{children}</LinkContainer> : <LinkContainer to={to} mode={mode} fontSize={fontSize} ref={refContainer} {...props}>{children}</LinkContainer>
  )
}

export default LinkComponent